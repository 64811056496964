import { createContext, useContext, useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { twJoin } from "tailwind-merge";
import UserInfo from "./UserInfo";
import NoticePopup from "../NoticePopup/NoticePopup";
import { ProfileContext } from "../UserProfile/UserProfile";
import { renderDateFormatDMY } from "../DashboardCalendarView/DashboardAddEventPopup";

const BasicInfoSection = (props: any) => {  
  const cookie = window.localStorage.getItem("cookie") ? false : true;  
  const { t } = props;
  const profileContext:any=useContext(ProfileContext);
  const profileData=profileContext.profileData?.employee;
  const profileDataPopup=profileContext.profileData?.noticeboard;
  const popupSatatus=profileContext.profileData?.popupSatatus;
  const [isOpen, setIsOpen] = useState(popupSatatus==true?(cookie==false?false:true):false);
  return (
    <>
      <UserInfo activeTab="basic_info">
        <div id="basic_info" className={twJoin("mt-4 max-h-[44vh] overflow-auto")}>
          <div className="grid grid-cols-2 text-left">
            <label className="py-2 text-body-md text-left block text-sat_darkgray400">
              {t("fullname")}
            </label>
            <p className="py-2 text-body-md sat_darkgray break-words">{profileData?.name}</p>
            <label className="py-2 text-body-md text-left block text-sat_darkgray400 break-words">
              {t("employeeID")}
            </label>
            <p className="py-2 text-body-md sat_darkgray break-words">{profileData?.employeeID}</p>
            <label className="py-2 text-body-md text-left block text-sat_darkgray400 break-words">
              {t("joiningDate")}
            </label>
            <p className="py-2 text-body-md sat_darkgray break-words">{profileData?.joinedDate?renderDateFormatDMY(new Date(profileData?.joinedDate),'.'):''}</p>
            <label className="py-2 text-body-md text-left block text-sat_darkgray400 break-words">
              {t("jobTitle")}
            </label>
            <p className="py-2 text-body-md sat_darkgray break-words">{profileData?.jobTitle}</p>    
            <label className="py-2 text-body-md text-left block text-sat_darkgray400 break-words">
              {t("department")}
            </label>
            <p className="py-2 text-body-md sat_darkgray break-words">{profileData?.department?.name}</p>              
            <label className="py-2 text-body-md text-left block text-sat_darkgray400 break-words">
              {t("location")}
            </label>
            <p className="py-2 text-body-md sat_darkgray break-words">{profileData?.location}</p>
            <label className="py-2 text-body-md text-left block text-sat_darkgray400 break-words">
              {t("workEmail")}
            </label>
            <a href={'mailto:'+profileData?.workEmail} className="py-2 text-body-md text-sat_link underline break-words">{profileData?.workEmail}</a>
            <label className="py-2 text-body-md text-left block text-sat_darkgray400 break-words">
              {t("mobileNo")}
            </label>
            <a href={'tel:'+profileData?.mobile} className="py-2 text-body-md text-sat_link underline break-words">{profileData?.mobile}</a>
            <label className="py-2 text-body-md text-left block text-sat_darkgray400 break-words">
              {t("medicalPlan")}
            </label>
            <a href={profileData?.medicalPlan} className="py-2 text-body-md text-sat_link underline break-words">{profileData?.medicalPlan}</a>
          </div>
        </div>
      </UserInfo>
      <NoticePopup isOpen={isOpen} setIsOpen={setIsOpen} data={profileDataPopup}/>
    </>
  );
};
export default withNamespaces((props) => props.namespaces)(BasicInfoSection);
