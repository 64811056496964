import { useState } from "react";
import { switchEyeHandler } from "../../reset-password/components";
import { withNamespaces } from "react-i18next";
import { twJoin } from "tailwind-merge";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import authHeader from "../../app/services/auth-header";
import { useNavigate } from "react-router-dom";

const ChangePassword = (props: any) => {
  const navigate=useNavigate();
  const [loading,setLoading]=useState<boolean>(false);
  const [message,setMessage]=useState<string>('');
  const { t } = props;
  const [formData, setFormData] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const checkWithOldPassword=()=>{
    if(formData.current_password==formData.new_password){
        setMessage("New password should not same with old password");
        return true;
    }else{
        setMessage('');
        return false
    };
  }
  const checkWitConfirmPassword=()=>{
    if(formData.new_password!==formData.confirm_password){
        setMessage("New password is not same with confirm password");
        return true;
    }else{
        setMessage('');
        return false
    };
  }
  const changePassowrd=()=>{
    if(formData.new_password!="" || formData.current_password!="" || formData.confirm_password!=""){
        if(checkWithOldPassword()){
            return;
        }
        if(checkWitConfirmPassword()){
            return;
        }
        axios.post(endpointUrl + "admin/password/change",
      {
        current_password: formData.current_password,
        new_password: formData.new_password,
        password_confirmation: formData.confirm_password,
      },
      {
        headers: authHeader(),
      })
      .then(function (response) {
        console.log("response ",response)
        if(response.status==200){
          localStorage.clear();
          window.location.href='/'
        }
      })
      .catch((reason) => {
        setMessage(reason?.message);
        console.log("reason ",reason)
      });
    }else{
      
    }
  }
  return (
    <div className="px-5 pt-8">
      <div className="password-div mb-5 flex justify-between items-center overflow-hidden bg-sat_lightgray rounded-lg relative">
        <input
          onChange={handleChange}
          type="password"
          name="current_password"
          placeholder={t("current_password")}
          className="bg-sat_lightgray text-body-md w-full font-secondary px-3 py-[10px] focus-within:outline-none focus:outline-none focus-visible:outline-none placeholder:text-sat_grayHint"
        />
        <button
          onClick={(event) => switchEyeHandler(event,t)}
          type="button"
          className="text-body-sm text-sat_darkgray font-secondary px-3 absolute right-0 top-1/2 -translate-y-1/2"
        >
          {t('show')}
        </button>
      </div>
      <div className="password-div mb-5 flex justify-between items-center overflow-hidden bg-sat_lightgray rounded-lg relative">
        <input
          onChange={handleChange}
          type="password"
          name="new_password"
          placeholder={t("new_password")}
          className="bg-sat_lightgray text-body-md w-full font-secondary px-3 py-[10px] focus-within:outline-none focus:outline-none focus-visible:outline-none placeholder:text-sat_grayHint"
        />
        <button
          onClick={(event) => switchEyeHandler(event,t)}
          type="button"
          className="text-body-sm text-sat_darkgray font-secondary px-3 absolute right-0 top-1/2 -translate-y-1/2"
        >
          {t('show')}
        </button>
      </div>
      <div className="password-div mb-5 flex justify-between items-center overflow-hidden bg-sat_lightgray rounded-lg relative">
        <input
          onChange={handleChange}
          type="password"
          name="confirm_password"
          placeholder={t("confirm_password")}
          className="bg-sat_lightgray text-body-md w-full font-secondary px-3 py-[10px] focus-within:outline-none focus:outline-none focus-visible:outline-none placeholder:text-sat_grayHint"
        />
        <button
          onClick={(event) => switchEyeHandler(event,t)}
          type="button"
          className="text-body-sm text-sat_darkgray font-secondary px-3 absolute right-0 top-1/2 -translate-y-1/2"
        >
         {t('show')}
        </button>
      </div>
      <div className="mt-5">
        {message?<p className="text-body-sm font-secondary px-3 text-sat_primary mb-5">{message}</p>:''}
        <button onClick={()=>changePassowrd()}
              type="button"
              className={twJoin("rounded-lg py-3 px-3 text-white text-center text-body-md font-bold font-secondary w-full bg-sat_primary")}
            >
              {t('change_password')}
            </button>
      </div>
    </div>
  );
};
export default withNamespaces((props) => props.namespaces)(ChangePassword);
